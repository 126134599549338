/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@layer {
  @tailwind base;
}

@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';
@import '@mantine/dropzone/styles.css';
@import '@mantine/nprogress/styles.css';
@import '@mantine/carousel/styles.css';

@import 'mantine-datatable/styles.layer.css';

table * {
  z-index: 0 !important;
}

/* Don't use small fonts to avoid zoom on iOS */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@keyframes strike {
  to {
    background-size: 100% 1px;
  }
}

.strike {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 0% 1px;
  animation: strike 150ms linear forwards;
}
